import { BASE_URL } from '@/lib/config';
import { ENDPOINTS } from '@/lib/constants';
import axiosInstance from '@/lib/interceptors';
import { AxiosError } from 'axios';
import {
  EditProfileBody,
  OrganizationUserBody,
  UserDetailsTypes,
  UserListBody,
  UserRoles,
  UsersListTypes,
} from '../interface/users.interface';
import { ApiResponse } from '@/modules/Organizations/interface/organization.interface';

export const UserCreateOrganizationUserService = async (
  body: OrganizationUserBody
) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}`,
      body
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const GetOrganizationUserRolesService = async (): Promise<
  UserRoles[]
> => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/${ENDPOINTS.ROLES}`);
    return response?.data?.data as UserRoles[];
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const ListUsersService = async (
  body: UserListBody
): Promise<UsersListTypes> => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USERS}/list`,
      body
    );
    return response?.data?.data as UsersListTypes;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const ResendInviteService = async (body: { email: string }) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}/invite/resend`,
      body
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const UserDetailsService = async (
  id: string
): Promise<UserDetailsTypes> => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}/${id}`
    );
    return response?.data?.data as UserDetailsTypes;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const EditProfileService = async (body: EditProfileBody) => {
  try {
    const response = await axiosInstance.patch(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}`,
      body
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const generateCsvService = async (body?: {
  start_date?: string;
  end_date?: string;
}) => {
  try {
    const response = await axiosInstance.get<ApiResponse<null>>(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/generate-csv`,
      {
        params: body,
      }
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};
