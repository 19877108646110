import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { USERS_LIST_INITIAL_VALUES } from "@/lib/constants";
import { InitialState } from "../types/users.types";
import { UserListBody } from "../../interface/users.interface";

const initialState: InitialState = {
  usersList: USERS_LIST_INITIAL_VALUES,
  isLoading: false,
};

const getUsersList = (state: InitialState, action: PayloadAction<UserListBody>) => state;

const setUsersList = (state: InitialState, action: PayloadAction<InitialState["usersList"]>) => ({
  ...state,
  usersList: action.payload,
});

const setIsLoading = (state: InitialState, action: PayloadAction<InitialState["isLoading"]>) => ({
  ...state,
  isLoading: action.payload,
});

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    getUsersList,
    setUsersList,
    setIsLoading,
  },
});

export default usersSlice;
