import Button from '@/components/Button';
import EditIcon from '@/src/assets/imagesComponents/EditIcon';
import NoHistoryIcon from '@/src/assets/imagesComponents/NoHistoryIcon';
import NoplansIcon from '@/src/assets/imagesComponents/NoPlansIcon';
import { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import OrganizationProfile from '../Components/OrganizationProfile';
import OrganizationHistory from '../Components/OrganizationHistory';
import OrganizationPlan from '../Components/OrganizationPlan';
import { getOrganizationHistory } from '../../services/organization.servces';
import { useStore } from '@/hooks/useRedux';
import organizationSlice from '../../store/slice/organization.slice';
import { useParams } from 'react-router';

const OrganizationDetails = () => {
  const { dispatch } = useStore();

  const { state } = useStore();

  const { id } = useParams<{
    id: string;
  }>();

  useEffect(() => {
    dispatch(organizationSlice.actions.resetOrganizationHistory());
    dispatch(organizationSlice.actions.getOrgDetails(id!));
    dispatch(organizationSlice.actions.getOrgPlans(id!));
  }, [id]);

  useEffect(() => {
    dispatch(
      organizationSlice.actions.getOrgHistory({
        id: id!,
        page: state.organizations.historyPage,
      })
    );
  }, [state.organizations.historyPage]);

  return (
    <div className="grid grid-cols-3 gap-5 w-full">
      <OrganizationProfile />
      <OrganizationHistory />
      <OrganizationPlan />
    </div>
  );
};

export default OrganizationDetails;
