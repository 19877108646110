import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { Dispatch, useEffect, useState } from 'react';
import { AnyAction } from 'redux';
import { RootState } from '@/store/index';
import settingSlice from '@/modules/Settings/store/slice/settings.slice';
import { NavigateFunction, useLocation, useNavigate } from 'react-router';

import Modal from 'react-modal';
import AddCustomer from '@/modules/Customer/pages/AddCustomer';
import {
  ModalState,
  TopNavData,
} from '@/modules/Settings/interface/settings.interface';

import NewProposalsIcon from '../../assets/images/svg/new_proposal_icon.svg';
import RightArrowIcon from '../../assets/images/svg/right_arrow.svg';
import { DETAILS, USER_ROLES } from '@/lib/constants';
import { capitalizeFirstLetter } from '@/lib/helper';
import { ORGANIZATIONS } from '@/src/routes/routesConstants';
import AddOrganization from '@/modules/Organizations/pages/Components/AddOrganization';
import DownloadUserModal from './DownloadUserModal';

const TopNav = (): JSX.Element => {
  const [showReportModal, setShowReportModal] = useState(false);

  const pagePath: string = window.location.pathname;
  const userRole: string | null = useAppSelector(
    (state: RootState) => state.auth.authMeData.role.name
  );
  const dispatch: Dispatch<AnyAction> = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();

  const TopNavData = useAppSelector(
    (state: RootState): TopNavData => state.settings.topNavData
  );
  const ModalState = useAppSelector(
    (state: RootState): ModalState => state.settings.modals
  );
  const customerName = useAppSelector(
    (state: RootState) => state.settings.customerName
  );

  const [isNotVisible, setIsNotVisible] = useState<boolean>(false);

  const handleModal = (buttonText: string): void => {
    switch (buttonText.toLocaleLowerCase().trim()) {
      case 'add organization':
        dispatch(settingSlice.actions.setAddOrganizationModalState(true));
        break;

      default:
        break;
    }
  };

  useEffect((): void => {
    if (!pagePath.includes(DETAILS.toLocaleLowerCase().trim())) {
      dispatch(
        settingSlice.actions.setCustomerName({ firstName: '', lastName: '' })
      );
      setIsNotVisible(false);
    } else {
      setIsNotVisible(true);
    }
  }, [pagePath]);

  const handleButtonComponent = (role: string) => {
    switch (role) {
      case USER_ROLES.SUPER_ADMIN:
        return <AddOrganization />;

      default:
        break;
    }
  };

  return (
    <div className="flex justify-between items-center">
      <div>
        {!customerName?.firstName ? (
          <h2 className="font-semibold text-2xl font-Inter text-blackDarkColor">
            {TopNavData.heading}
          </h2>
        ) : (
          <div className="flex items-center">
            <h6
              className="font-medium text-sm font-Inter text-gray-500 mr-3 cursor-pointer"
              onClick={() => {
                navigate(ORGANIZATIONS);
              }}
            >
              {TopNavData.heading}
            </h6>
            <div className="w-5">
              <img src={RightArrowIcon} alt="*" />
            </div>
            <h6
              className="font-medium text-sm font-Inter"
              style={{ marginLeft: '0.5em' }}
            >{`${capitalizeFirstLetter(
              customerName?.firstName
            )} ${capitalizeFirstLetter(customerName?.lastName)}`}</h6>
          </div>
        )}
      </div>

      {!isNotVisible && (
        <div className="flex gap-4">
          <button
            className="flex border border-buttonColorGreen text-whi bg-white items-center h-12 px-6 rounded text-buttonColorGreen"
            onClick={() => {
              setShowReportModal(true);
            }}
          >
            <p className=" font-Inter font-semibold text-base">Download CSV</p>
          </button>
          {/* <button
            className="flex border border-buttonLightGreen text-whi bg-buttonLightGreen items-center h-12 px-6 rounded text-white"
            onClick={() => handleModal(TopNavData.buttonText)}
          > */}
            {/* <div className="mr-4">
						<img src={NewProposalsIcon} alt="*" className="w-7" />
					</div> */}
            {/* <p className=" font-Inter font-semibold text-base">
              {TopNavData.buttonText}
            </p>
          </button> */}
        </div>
      )}

      <Modal
        isOpen={ModalState.addOrganization}
        ariaHideApp={false}
        className="mx-auto  rounded shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        {handleButtonComponent(userRole as string)}
      </Modal>
      <DownloadUserModal
        open={showReportModal}
        onRequestClose={() => setShowReportModal(false)}
      />
    </div>
  );
};

export default TopNav;
