import { call, put, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { ListUsersService } from "../../service/users.service";
import { UserListBody, UsersListTypes } from "../../interface/users.interface";
import usersSlice from "../slice/users.slice";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

function* UsersListSaga(action: PayloadAction<UserListBody>) {
  try {
    yield put(usersSlice.actions.setIsLoading(true));
    const response: UsersListTypes = yield call(ListUsersService, action.payload);
    if (response) {
      yield put(usersSlice.actions.setUsersList(response));
    }
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: "colored" });
  } finally {
    yield put(usersSlice.actions.setIsLoading(false));
  }
}

export default function* userSaga() {
  yield takeEvery(usersSlice.actions.getUsersList.type, UsersListSaga);
}
