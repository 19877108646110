import React, { useEffect, useRef } from 'react';
import Button from '@/components/Button';
import EditIcon from '@/src/assets/imagesComponents/EditIcon';
import NoHistoryIcon from '@/src/assets/imagesComponents/NoHistoryIcon';
import { useStore } from '@/hooks/useRedux';
import Skeleton from 'react-loading-skeleton';
import organizationSlice from '../../store/slice/organization.slice';

import InfiniteScroll from 'react-infinite-scroller';

const OrganizationHistory = () => {
  const { state, dispatch } = useStore();
  const {
    history: orgHistory,
    historyLoading,
    historyLastPage,
    historyPage,
  } = state.organizations;

  const scrollParentRef = useRef<HTMLDivElement>(null);

  return (
    <div className="shadow-pageShadow bg-white max-h-fit rounded-lg ml-4">
      <div className="flex items-center justify-between pt-4 pb-3 px-6 border-b border-sideNavButtonBg">
        <span className="text-lg text-greyBlackColor font-semibold">
          History
        </span>
      </div>

      <div className="overflow-y-auto h-64 px-6 pt-3" ref={scrollParentRef}>
        <div>
          <ul className="list">
            <InfiniteScroll
              pageStart={0}
              loadMore={() => {
                dispatch(
                  organizationSlice.actions.setHistoryPage({
                    historyPage: state.organizations.historyPage + 1,
                  })
                );
              }}
              hasMore={
                historyPage < historyLastPage && !historyLoading ? true : false
              }
              useWindow={false}
              getScrollParent={() => scrollParentRef.current!}
            >
              {orgHistory.map((item, index) => (
                <>
                  <li
                    key={`${item.message + index}`}
                    className="flex items-center relative py-2"
                  >
                    <div
                      className={`dot w-[6px] h-[6px] rounded-full  ${
                        index === 0 ? 'bg-[#98A2B3]' : 'bg-greyBlackColor'
                      }`}
                    />
                    <span
                      className={`text-sm font-medium pl-3 ${
                        index === 0 ? 'text-[#98A2B3]' : 'text-greyBlackColor'
                      }`}
                    >
                      {item.message}
                    </span>
                  </li>
                </>
              ))}
            </InfiniteScroll>
          </ul>
        </div>
        {historyLoading && <Skeleton count={orgHistory.length === 0 ? 8 : 3} />}

        {/* if there is no history */}
        {!historyLoading && orgHistory.length === 0 && (
          <div className="flex flex-col justify-center items-center">
            <NoHistoryIcon />

            <span className="text-lg font-medium text-interBlack py-1">
              No History
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationHistory;
